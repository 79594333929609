import 'babel-polyfill';

import jQuery from 'jquery';
import Headroom from 'headroom.js';
import 'bootstrap';
// import blocks from "./blocks/all";
import './theme.scss';

window.WebFontConfig = {
    google: {
        families: [
            'Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i:latin-ext&display=swap',
        ]
    },
    custom: {
        families: [
            'DIN Pro:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i:latin-ext&display=swap',
            'DIN Pro Cond:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i:latin-ext&display=swap',
        ]
    }
};

(function (d)
{
    var wf = d.createElement('script'), s = d.scripts[0];
    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
    wf.async = true;
    s.parentNode.insertBefore(wf, s);
})(document);

if (process.env.NODE_ENV === 'development')
{
    import('./debug.scss').then(() =>
    {
    });
}

jQuery.root = jQuery(document);

window.jQuery = jQuery;
window.$ = jQuery;


jQuery('html,.no-js').removeClass('no-js').addClass('js');
jQuery($ =>
{
    $('.no-js').removeClass('no-js').addClass('js');

    $('html').addClass('atlantis-logged-unknown');

    if (typeof window.atlantisjs === 'object' && typeof window.atlantisjs.base_url === 'string')
    {
        window.url_atlantis_publieksomgeving = window.atlantisjs.base_url;
    }

    if (typeof window.url_atlantis_publieksomgeving !== 'undefined')
    {
        $.ajax({
            url: url_atlantis_publieksomgeving + 'service.php',
            jsonp: 'callback',
            dataType: 'jsonp',
            data: {
                service: 'Login',
                method: 'IsIngelogd'
            },

            xhrFields: {
                withCredentials: true
            }
        })
            // $.getJSON(url_atlantis_publieksomgeving + 'service.php?service=Login&method=IsIngelogd&callback=?')
            .then(response =>
            {
                $('html')
                    .removeClass('atlantis-logged-unknown')
                    .toggleClass('atlantis-logged-in', response.status)
                    .toggleClass('atlantis-logged-out', !response.status);
            });
    }

    /*
    $('.page-block,.sub-block').each((k, block) => {
        let m = block.className.match(/\bblock-([^\s]+)/);
        if (m && m.length) {
            let block_type = m[1].replace(/-([a-z])/g, function (g) {
                return g[1].toUpperCase();
            });
            if (block_type in blocks) {
                blocks[block_type].then(block_import => {
                    if (block_import.default) {
                        block_import.default(block, k);
                    }
                });
            }
        }
    });
*/
    let header = document.getElementById('site-header');
    if (header)
    {
        let headroom = new Headroom(header, {
            // offset: 180,
            // onPin() {
            // },
            onUnpin()
            {
                $('#header-menu-collapse, #header-menu .nav-drop-collapse').collapse('hide');
            },
            // onTop() {
            // },
            // onNotTop() {
            // },
            // onBottom() {
            // },
            // onNotBottom() {
            // }
        });

        headroom.init();
    }
});